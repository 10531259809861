import $ from 'jquery';
import 'what-input';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//import Scrollbar from 'smooth-scrollbar';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


// Foundation
$(document).foundation();

// GSAP
gsap.registerPlugin(ScrollTrigger);




// Smooth scroll setup
// const bodyScrollBar = Scrollbar.init(document.querySelector('#scrollContent'));
// bodyScrollBar.setPosition(0, 0);
// bodyScrollBar.track.xAxis.element.remove();
//
// ScrollTrigger.scrollerProxy("body", {
//   scrollTop(value) {
//     if (arguments.length) {
//       bodyScrollBar.scrollTop = value;
//     }
//     return bodyScrollBar.scrollTop;
//   }
// });
//


// Topbar
ScrollTrigger.create({
  trigger: ".hero-banner",
  start: "top+=100 top",
  //markers:true,
  onEnter:() => $('.topbar').toggleClass('active'),
  onLeaveBack:() => $('.topbar').toggleClass('active'),
});


// gsap.to(".hero-banner", {
//   scrollTrigger: ".hero-banner", // start the animation when ".box" enters the viewport (once)
//   x: 500,
//   duration: 3
// });
